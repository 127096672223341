import React from "react";

import { Component } from "react";
//import { createWorker } from "tesseract.js";

import "./football-squares.css";

class FootballSquares extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teamOne: "Bengals",
      teamOneScore: 0,
      teamOneColor: "#FB4F14",
      teamTwo: "Rams",
      teamTwoScore: 0,
      teamTwoColor: "#003594",
      selectedLastDigit: 0,
      topRow: Array(10).fill(), //this.shuffle([...Array(10).keys()]),
      leftCol: Array(10).fill(), //this.shuffle([...Array(10).keys()]),
      squares: Array(10)
        .fill()
        .map(() => Array(10).fill()),
      isInputSectionHidden: false,
    };
  }

  // http://stackoverflow.com/questions/962802#962890
  shuffle(array) {
    let temp;
    let current;
    let top = array.length;
    if (top)
      while (--top) {
        current = Math.floor(Math.random() * (top + 1));
        temp = array[current];
        array[current] = array[top];
        array[top] = temp;
      }
    return array;
  }

  getLastDigitOfScore(score) {
    return score % 10;
  }

  componentDidMount() {
    /*
    const worker = createWorker({
      logger: m => console.log(m), // Add logger here
    });

    (async () => {
      await worker.load();
      await worker.loadLanguage("eng");
      await worker.initialize("eng");
      const {
        data: { text },
      } = await worker.recognize("http://localhost:8000/squares.jpg"); // "http://localhost:8000/eng_bw.png"
      console.log(text);
      await worker.terminate();
    })();
    */
  }

  handleInputChange(nameOfField) {
    return function setStateForField(value) {
      let myNewState = {};
      myNewState[nameOfField] = value;
      this.setState(myNewState);
    }.bind(this);
  }

  isCurrentScoreSquare(row, col) {
    return (
      this.getLastDigitOfScore(this.state.teamTwoScore) ===
        this.state.leftCol[row] &&
      this.getLastDigitOfScore(this.state.teamOneScore) ===
        this.state.topRow[col]
    );
  }

  isOneScoreAway(row, col, possibleScores) {
    let teamOneEndDigit = this.state.topRow[col];
    let teamTwoEndDigit = this.state.leftCol[row];

    const currentTeamOneScore = parseInt(this.state.teamOneScore);
    const currentTeamTwoScore = parseInt(this.state.teamTwoScore);
    let commonTeamOneScores = [];
    let commonTeamTwoScores = [];

    possibleScores.forEach(commonScore => {
      commonTeamOneScores.push(currentTeamOneScore + commonScore);
      commonTeamTwoScores.push(currentTeamTwoScore + commonScore);
    });

    let isCommonScoreAway = false;
    commonTeamOneScores.forEach(teamOneScore => {
      console.log(teamOneScore);
      if (
        this.getLastDigitOfScore(teamOneScore) === teamOneEndDigit &&
        this.getLastDigitOfScore(currentTeamTwoScore) === teamTwoEndDigit
      ) {
        isCommonScoreAway = true;
      }
    });
    commonTeamTwoScores.forEach(teamTwoScore => {
      if (
        this.getLastDigitOfScore(currentTeamOneScore) === teamOneEndDigit &&
        this.getLastDigitOfScore(teamTwoScore) === teamTwoEndDigit
      ) {
        isCommonScoreAway = true;
      }
    });

    return isCommonScoreAway;
  }

  isOneCommonScoreAway(row, col) {
    return this.isOneScoreAway(row, col, [3, 7]);
  }

  isOneUncommonScoreAway(row, col) {
    return this.isOneScoreAway(row, col, [2, 6, 8]);
  }

  onChangeSelectedPlayerValue(name) {
    this.setState({
      selectedPlayer: name,
    });
  }

  resetSquares() {
    this.setState({
      topRow: Array(10).fill(),
      leftCol: Array(10).fill(),
      squares: Array(10)
        .fill()
        .map(() => Array(10).fill()),
      selectedLastDigit: 0,
    });
  }

  generateScoreCells() {
    this.setState({
      topRow: this.shuffle([...Array(10).keys()]),
      leftCol: this.shuffle([...Array(10).keys()]),
    });
  }

  registerTopRowScoreCellClick(index) {
    // Disable changes if input section is hidden.
    if (this.state.isInputSectionHidden) {
      return;
    }
    let newTopRow = this.state.topRow;
    newTopRow[index] = this.state.selectedLastDigit;
    let newSelectedLastDigit = this.state.selectedLastDigit + 1;
    if (newSelectedLastDigit === 10) {
      newSelectedLastDigit = 0;
    }
    this.setState({
      topRow: newTopRow,
      selectedLastDigit: newSelectedLastDigit,
    });
  }

  registerLeftColScoreCellClick(index) {
    // Disable changes if input section is hidden.
    if (this.state.isInputSectionHidden) {
      return;
    }
    let newleftCol = this.state.leftCol;
    newleftCol[index] = this.state.selectedLastDigit;
    let newSelectedLastDigit = this.state.selectedLastDigit + 1;
    if (newSelectedLastDigit === 10) {
      newSelectedLastDigit = 0;
    }
    this.setState({
      leftCol: newleftCol,
      selectedLastDigit: newSelectedLastDigit,
    });
  }

  registerCellClick(rowIndex, colIndex) {
    // Disable changes if input section is hidden.
    if (this.state.isInputSectionHidden) {
      return;
    }
    let newSquares = this.state.squares;
    newSquares[rowIndex][colIndex] = this.state.selectedPlayer;
    this.setState({
      squares: newSquares,
    });
  }

  toggleHideInputSection() {
    this.setState({ isInputSectionHidden: !this.state.isInputSectionHidden });
  }

  onChangeSelectedLastDigit(lastDigit) {
    let lastDigitInt = parseInt(lastDigit);
    this.setState({ selectedLastDigit: lastDigitInt });
  }

  render() {
    return (
      <div>
        <div class="inputCard">
          <h2>Enter your teams!</h2>
          <div>Keep scores updated here throughout the game.</div>
          <div class="inputRow">
            <input
              class="input colorInput"
              type="color"
              id="head"
              name="teamOne"
              value={this.state.teamOneColor}
              onChange={e =>
                this.handleInputChange("teamOneColor")(e.target.value)
              }
            />
            <label for="teamOne">Team</label>
            <input class="input teamNameInput" value={this.state.teamOne} />
            Score
            <input
              class="input teamScoreInput"
              value={this.state.teamOneScore}
              type="number"
              min="0"
              max="99"
              onChange={e =>
                this.handleInputChange("teamOneScore")(e.target.value)
              }
            />
          </div>
          <div class="inputRow">
            <input
              class="input colorInput"
              type="color"
              id="head"
              name="teamTwo"
              value={this.state.teamTwoColor}
              onChange={e =>
                this.handleInputChange("teamTwoColor")(e.target.value)
              }
            />
            <label for="teamTwo">Team</label>
            <input class="input teamNameInput" value={this.state.teamTwo} />
            Score
            <input
              class="input teamScoreInput"
              value={this.state.teamTwoScore}
              type="number"
              min="0"
              max="99"
              onChange={e =>
                this.handleInputChange("teamTwoScore")(e.target.value)
              }
            />
          </div>
        </div>
        <div class="inputCard">
          <div class="inputSectionHeader">
            <h2>Fill your squares!</h2>
            <div className={this.state.isInputSectionHidden ? "hidden" : ""}>
              <button
                class="button"
                onClick={this.toggleHideInputSection.bind(this)}
              >
                &#x25BC;
              </button>
            </div>
            <div className={this.state.isInputSectionHidden ? "" : "hidden"}>
              <button
                class="button"
                onClick={this.toggleHideInputSection.bind(this)}
              >
                &#x25B2;
              </button>{" "}
            </div>
          </div>
          <div className={this.state.isInputSectionHidden ? "hidden" : ""}>
            <div>
              First, enter the top row and left column (colored) last digit
              score numbers by tapping on the cells. The first tap will enter 0,
              the second tap will enter 1, etc.
            </div>
            <div class="inputRow">
              <div>
                Or,{" "}
                <button
                  class="button"
                  onClick={this.generateScoreCells.bind(this)}
                >
                  Generate
                </button>{" "}
                random 0-9 values for the top row and left column.
              </div>
            </div>
            <div class="inputRow">
              <div>Then, enter a player's initials:</div>
              <div>
                <input
                  type="text"
                  maxlength="8"
                  class="input playerInput"
                  value={this.state.selectedPlayer}
                  onChange={e =>
                    this.onChangeSelectedPlayerValue(e.target.value)
                  }
                />
              </div>
            </div>
            <div>Tap on the squares the player owns.</div>
            <div>
              For each player, repeat entering initials and tapping squares.
            </div>
            <div class="inputRow">
              <div>
                <button class="button" onClick={this.resetSquares.bind(this)}>
                  Reset
                </button>{" "}
                if you make a mistake.
              </div>
            </div>
          </div>
          <div className={this.state.isInputSectionHidden ? "" : "hidden"}>
            <button
              class="button"
              onClick={this.toggleHideInputSection.bind(this)}
            >
              Show
            </button>{" "}
            the square input section.
          </div>
          <div class="tableContainer">{this.createTable()}</div>
          {/* Tesseract Stuff 
                    <img class="hidden" src={`eng_bw.png`} alt="Testing 123" />
          <div class="tg-wrap"></div>
          */}
        </div>
      </div>
    );
  }

  createTable() {
    let topRow = [];
    this.state.topRow.forEach((element, index) => {
      topRow.push(
        <td
          class="scoreCell"
          style={{ backgroundColor: this.state.teamOneColor }}
          onClick={() => {
            this.registerTopRowScoreCellClick(index);
          }}
        >
          {element}
        </td>
      );
    });

    let bottomRows = [];
    this.state.squares.forEach((row, rowIndex) => {
      let tempRow = [];
      tempRow.push(
        <td
          class="scoreCell"
          style={{ backgroundColor: this.state.teamTwoColor }}
          onClick={() => {
            this.registerLeftColScoreCellClick(rowIndex);
          }}
        >
          {this.state.leftCol[rowIndex]}
        </td>
      );
      row.forEach((col, colIndex) => {
        if (this.isCurrentScoreSquare(rowIndex, colIndex)) {
          tempRow.push(
            <td
              class="current"
              onClick={() => {
                this.registerCellClick(rowIndex, colIndex);
              }}
            >
              {col}
            </td>
          );
        } else if (this.isOneCommonScoreAway(rowIndex, colIndex)) {
          tempRow.push(
            <td
              class="nextCommon"
              onClick={() => {
                this.registerCellClick(rowIndex, colIndex);
              }}
            >
              {col}
            </td>
          );
        } else if (this.isOneUncommonScoreAway(rowIndex, colIndex)) {
          tempRow.push(
            <td
              class="nextUncommon"
              onClick={() => {
                this.registerCellClick(rowIndex, colIndex);
              }}
            >
              {col}
            </td>
          );
        } else {
          tempRow.push(
            <td
              onClick={() => {
                this.registerCellClick(rowIndex, colIndex);
              }}
            >
              {col}
            </td>
          );
        }
      });
      bottomRows.push(<tr>{tempRow}</tr>);
    });

    return (
      <table class="squaresTable">
        <tr>
          <td></td>
          {topRow}
        </tr>
        {bottomRows}
      </table>
    );
  }
}

export default FootballSquares;
