import React from "react";

import { Component } from "react";
import { textChangeRangeIsUnchanged } from "typescript";

import Spinner from "./spinner";
import Utils from "../helpers/utils";

import LongTermLeverageConst from "../helpers/long-term-leverage-constants";

//μ = .11, σ = .15, r = .06
//(0.11 - r) / (.15*.15)
//(0.092 - r) / (.2*.2)
//(0.101 - r) / (.201*.201)
//(0.121 - r) / (.165*.165)

class KellyBacktester extends Component {
  constructor(props) {
    super(props);
    this.commentSubmitForm = React.createRef();

    this.STARTING_STATE = {
      fractionFormula: "(0.116 - r) / (.186*.186)", //"(0.11 - r) / (.15*.15)",
      rebalancePeriod: 365,
      borrowBenchmark: LongTermLeverageConst.TBILL,
      borrowBenchmarkAdj: 1.5,
      lendBenchmark: LongTermLeverageConst.TBILL,
      lendBenchmarkAdj: 1,
      refinancePeriod: 365,
      minEquity: 30,
      sp500data: null,
      loanData: null,
      result: "",
      returnsSource: "HISTORIC",
    };

    this.state = this.STARTING_STATE;
  }

  getFieldUpdateFunction(fieldName) {
    return function (e) {
      let stateUpdate = {};
      if (e.target.type === "checkbox") {
        stateUpdate[fieldName] = e.target.checked;
      } else {
        stateUpdate[fieldName] = e.target.value;
      }
      this.setState(stateUpdate);
    }.bind(this);
  }

  noOp(e) {
    e.preventDefault();
  }

  runBacktest(e) {
    e.preventDefault();
    console.log("STARTING BACKTEST");

    if (this.state.sp500data === null || this.state.loanData === null) {
      // Data hasn't been loaded yet
      console.log(
        "Ignoring button press",
        this.state.sp500data,
        this.state.loanData
      );
      return;
    }

    const loanJson = Utils.csvToJsonObject(this.state.loanData, 0);
    let sandpJson = Utils.csvToJsonObject(this.state.sp500data, 0);

    const dates = Object.keys(loanJson);
    const simulatedData = Utils.generateNormalMarketData(
      0.0097328410078193,
      0.05372449055112752,
      dates.length,
      dates
    );

    sandpJson =
      this.state.returnsSource === "SYNTHETIC" ? simulatedData : sandpJson;

    // Calc avg and std dev
    let returnsArray = [];
    for (let date in sandpJson) {
      let ret = sandpJson[date]["return"];
      if (!isNaN(ret)) {
        returnsArray.push(parseFloat(sandpJson[date]["return"]));
      }
    }
    returnsArray.pop(); //idk why the last element is undefiend

    console.log("ARRAY", returnsArray);

    console.log(
      "MEAN",
      Utils.mean(returnsArray),
      Utils.mean(returnsArray) * 12
    );
    console.log(
      "STDDEV",
      Utils.standardDeviation(returnsArray),
      Utils.standardDeviation(returnsArray) * Math.sqrt(12)
    );

    // (0.092 - r) / (.2*.2)
    let resultString = "k*  maxL minL return minEquity\n";
    for (let i = 0; i <= 2; i += 0.1) {
      let result = this.backtest(
        sandpJson,
        loanJson,
        new Function(
          "r", // rate
          // Fractional Kelly
          `let result = ${this.state.fractionFormula}*${i}; return result;`

          // Fractional Kelly but never be less than 100% equities
          //`let result =  ${this.state.fractionFormula}*${i}; console.log(r, result,"${this.state.fractionFormula}",${i}); return result;`
          //`let result =  Math.max(${this.state.fractionFormula}*${i},1); /*console.log(result);*/return result;`

          // Apply fractional Kelly to leverage only
          //`let fractForm = ${this.state.fractionFormula}; let result = fractForm > 1 ? (1 + (fractForm - 1)*${i}) : fractForm; /*console.log(${i}, fractForm, result);*/ return result;`
        ),
        this.state.minEquity,
        this.state.refinancePeriod,
        this.state.rebalancePeriod,
        this.state.borrowBenchmark,
        this.state.borrowBenchmarkAdj,
        this.state.lendBenchmark,
        this.state.lendBenchmarkAdj,
        i
      );
      resultString =
        resultString +
        `${result.kellyFraction
          .toString()
          .padEnd(4)} ${result.maxLeverage
          .toString()
          .padEnd(4)} ${result.minLeverage
          .toString()
          .padEnd(4)} ${result.medianReturn.padEnd(
          6
        )} ${result.minEquity.padEnd(6)}\n`;
    }
    this.setState({ result: resultString });
  }

  backtest(
    sandpJson,
    loanJson,
    fractionFormula,
    requiredMinEquity,
    refinancePeriod,
    rebalancePeriod,
    borrowBenchmark,
    borrowBenchmarkAdj,
    lendBenchmark,
    lendBenchmarkAdj,
    kellyFraction
  ) {
    const STARTING_BALLANCE = 100000;
    let histogram = {};
    let histogramCount = {};

    // Determine maximum possible start and end dates based off of what data we have for loan/borrow rates for
    let startIndex = Math.max(
      this.getFirstDateIndex(loanJson, borrowBenchmark),
      this.getFirstDateIndex(loanJson, lendBenchmark)
    );
    let endIndex = Math.min(
      this.getLastDateIndex(loanJson, borrowBenchmark),
      this.getLastDateIndex(loanJson, lendBenchmark)
    );

    let marketDates = Object.keys(sandpJson);
    let totalPeriods = 0;
    let failingPeriods = 0;
    let returnArray = [];
    let globalMinEquity = 1;
    let maxLeverage = 0;
    let minLeverage = 9999999999;
    for (
      let startDateIndex = startIndex;
      startDateIndex <= endIndex;
      startDateIndex += 1
    ) {
      let startDate = marketDates[startDateIndex];

      let minEquity = 1.0;

      let lendRate =
        this.getNextRate(loanJson, lendBenchmark, startDate) / 100 +
        lendBenchmarkAdj / 100;
      let borrowRate =
        this.getNextRate(loanJson, borrowBenchmark, startDate) / 100 +
        borrowBenchmarkAdj / 100;

      // Starting values are determined by leverage fraction
      let lendFraction = fractionFormula(lendRate);
      let borrowFraction = fractionFormula(borrowRate);
      let investFraction = this.resolveFractionFormula(
        lendFraction,
        borrowFraction
      );

      let investmentBalance = investFraction * STARTING_BALLANCE;
      let borrowedBalance = Math.max(
        STARTING_BALLANCE * (investFraction - 1),
        0
      );
      let riskFreeBalance = Math.max(STARTING_BALLANCE - investmentBalance, 0);

      //console.log("STARTING", investmentBalance, borrowedBalance, riskFreeBalance);

      let daysTotal = 0;
      let daysSinceLastRebalance = 0;
      let daysSinceLastRefinance = 0;

      let dayStartPriceData = null;
      let previousTestDate = null;

      for (
        let endDateIndex = startDateIndex;
        endDateIndex <= endIndex;
        endDateIndex++
      ) {
        let endDate = marketDates[endDateIndex];
        let dayEndPriceData = sandpJson[endDate];
        if (dayStartPriceData !== null && previousTestDate !== null) {
          let elapsedDays = Utils.getDaysBetween(previousTestDate, endDate);
          daysSinceLastRefinance += elapsedDays;
          daysSinceLastRebalance += elapsedDays;
          daysTotal += elapsedDays;

          let periodPercentageChange = dayEndPriceData["return"];

          let periodStartingNetBalance =
            investmentBalance - borrowedBalance + riskFreeBalance;

          // We'd use this if we had historic prices instead of changes
          /*Utils.percentIncrease(
            dayStartPriceData["return"],
            dayEndPriceData["return"]
          );*/

          // Update Investment
          investmentBalance =
            investmentBalance + investmentBalance * periodPercentageChange;

          // Update Borrowing
          // using 365 days per year
          // Accounting here could be tricky. Some lenders use 365/360 to calculate the daily loan rate (apparently used by most brokers for margin loans) which is advantageous to the lender
          // Seems very misleading to me, it means the annual rate is not really the annual rate. TODO: make this configurable
          borrowedBalance =
            borrowedBalance +
            ((borrowedBalance * borrowRate) / 365) * elapsedDays;
          riskFreeBalance =
            riskFreeBalance +
            ((riskFreeBalance * lendRate) / 365) * elapsedDays;

          // Calculate and update equity
          let equity =
            1 - borrowedBalance / (investmentBalance + riskFreeBalance);
          if (equity < minEquity) {
            minEquity = equity;
          }
          if (minEquity < requiredMinEquity / 100) {
            //console.log("FAIL", minEquity, requiredMinEquity / 100);
            failingPeriods++;
            investmentBalance = 0;
            borrowedBalance = 0;
          }
          totalPeriods++;

          let annualizedReturn = Utils.CAGR(
            STARTING_BALLANCE,
            riskFreeBalance + investmentBalance - borrowedBalance,
            daysTotal / 365 // TODO: leap year???
          );
          returnArray.push(annualizedReturn);
          if (minEquity < globalMinEquity) {
            globalMinEquity = minEquity;
          }

          /*
          console.log(
            previousTestDate,
            endDate,
            Utils.getDaysBetween(previousTestDate, endDate),
            Utils.formatMoney(investmentBalance),
            Utils.formatPercentage(periodPercentageChange, 2),
            Utils.formatMoney(borrowedBalance),
            Utils.formatPercentage(borrowRate, 2),
            Utils.formatPercentage(lendRate, 2),
            Utils.formatPercentage(equity, 2),
            Utils.formatPercentage(minEquity, 2),
            Utils.round(
              investmentBalance /
                (investmentBalance - borrowedBalance + riskFreeBalance),
              1
            ),
            Utils.formatMoney(
              investmentBalance - borrowedBalance + riskFreeBalance
            )
          );
          //*/

          let periodEndingNetBalance =
            investmentBalance - borrowedBalance + riskFreeBalance;

          // Build histogram of return by interest rate (we only want to track this for the full length)
          if (startDateIndex === startIndex) {
            let periodCAGR = Utils.CAGR(
              periodStartingNetBalance,
              periodEndingNetBalance,
              elapsedDays / 365 // TODO: leap year???
            );

            if (!isNaN(periodCAGR)) {
              let bucket = Utils.round(borrowRate, 2);

              let val = histogram[bucket];
              val = val === undefined ? 0 : val;
              histogram[bucket] = val + periodCAGR;

              let val2 = histogramCount[bucket];
              val2 = val2 === undefined ? 0 : val2;
              histogramCount[bucket] = val2 + 1;
            }
          }

          // Refinance Loan
          if (daysSinceLastRefinance >= refinancePeriod) {
            borrowRate =
              this.getNextRate(loanJson, borrowBenchmark, endDate) / 100 +
              borrowBenchmarkAdj / 100;
            daysSinceLastRefinance = 0;
          }

          // Rebalance leverage
          if (daysSinceLastRebalance >= rebalancePeriod) {
            lendRate =
              this.getNextRate(loanJson, lendBenchmark, endDate) / 100 +
              lendBenchmarkAdj / 100;
            let updatedLendFraction = fractionFormula(lendRate);
            let updatedBorrowFraction = fractionFormula(borrowRate);
            let updatedInvestFraction = this.resolveFractionFormula(
              updatedLendFraction,
              updatedBorrowFraction
            );

            // Just checking to see if suggested leverage is ever less than 1
            /*
            if (updatedInvestFraction < 1) {
              console.log(
                "Don't be 100% equities",
                endDate,
                Utils.formatPercentage(updatedInvestFraction, 1),
                lendRate
              );
            }*/

            /*
            console.log(
              "LEND",
              lendBenchmark,
              lendBenchmarkAdj,
              this.getNextRate(loanJson, lendBenchmark, endDate),
              lendRate,
              "BORROW",
              borrowBenchmark,
              borrowBenchmarkAdj,
              this.getNextRate(loanJson, borrowBenchmark, endDate),
              borrowRate
            );//*/

            /*
            console.log(
              "LEND",
              Utils.round(updatedLendFraction, 2),
              "BORROW",
              Utils.round(updatedBorrowFraction, 2),
              "END",
              Utils.round(updatedInvestFraction, 2)
            );//*/

            /*
            console.log(
              "REBAL",
              lendBenchmark,
              startDate,
              Utils.round(updatedLendFraction, 2),
              Utils.formatPercentage(lendRate, 2),
              Utils.round(updatedBorrowFraction, 2),
              Utils.formatPercentage(borrowRate, 2),
              Utils.round(updatedInvestFraction, 2)
            ); //*/

            let netBalance =
              investmentBalance - borrowedBalance + riskFreeBalance;

            let targetInvestment = netBalance * updatedInvestFraction;
            let diff = targetInvestment - investmentBalance;

            // Update leverage extremes (for display)
            if (updatedInvestFraction > maxLeverage) {
              maxLeverage = updatedInvestFraction;
            }
            if (updatedInvestFraction < minLeverage) {
              minLeverage = updatedInvestFraction;
            }

            if (diff > 0) {
              // More investment
              let loanRequired = -Math.min(riskFreeBalance - diff, 0); // Borrow $0 additional if we have riskFree money available
              riskFreeBalance = Math.max(riskFreeBalance - diff, 0); // Risk free can't go below $0

              //let loanRequired = Math.max(diff - riskFreeBalance, 0);
              //riskFreeBalance = Math.max(riskFreeBalance - diff, 0);
              borrowedBalance += loanRequired;
            } else {
              // Less investment

              // Pay off as much of the loan as we can
              let loanRepayment = Math.min(borrowedBalance, Math.abs(diff));
              borrowedBalance -= loanRepayment;

              // The rest goes in the riskFreeReturn bucket
              let riskFreeBalanceIncrease = Math.abs(diff) - loanRepayment;
              riskFreeBalance += riskFreeBalanceIncrease;
              //riskFreeBalance += Math.abs(loanRepayment + diff);
              // TODO: Apply interest to uninvested cash (what is this?)
              // TODO: what if borrowing rate < risk free rate (might be impossible in regular circumstances.. not something that can be relied on)
            }
            investmentBalance += diff;

            daysSinceLastRebalance = 0;

            /*
            console.log(
              "Rebalance!",
              "Lend rate " + Utils.formatPercentage(lendRate, 2),
              "Borrow rate " + Utils.formatPercentage(borrowRate, 2),
              Utils.formatPercentage(riskFreeBalance / netBalance),
              Utils.formatPercentage(investmentBalance / netBalance),
              Utils.formatPercentage(borrowedBalance / netBalance),
              Utils.formatMoney(netBalance)
            );
            console.warn(
              updatedLendFraction,
              updatedBorrowFraction,
              updatedInvestFraction,
              endDate
            );

            //*/
          }
        }
        dayStartPriceData = dayEndPriceData;
        previousTestDate = endDate;

        //console.log("--------------------");
      }
      /*
      console.log(
        "FINISHED",
        startDate,
        failingPeriods,
        totalPeriods,
        Utils.formatPercentage(
          (totalPeriods - failingPeriods) / totalPeriods,
          2
        ),
        Utils.formatPercentage(Utils.median(returnArray), 2)
      );
      */
    }

    // TODO:
    // Adding money
    // LEverage based on interest rate

    /*
    console.log(
      "DONE!! ",
      "Successful periods: " +
        Utils.formatPercentage((totalPeriods - failingPeriods) / totalPeriods),
      "Median Return: " + Utils.formatPercentage(Utils.median(returnArray), 2),
      "Minimum Equity: " + Utils.formatPercentage(globalMinEquity, 2)
    );*/
    console.log(
      Utils.round(kellyFraction, 2),
      Utils.round(maxLeverage, 2),
      Utils.round(minLeverage, 2),
      Utils.formatPercentage(Utils.median(returnArray), 2),
      Utils.formatPercentage(globalMinEquity, 2),
      Utils.formatPercentage((totalPeriods - failingPeriods) / totalPeriods)
    );

    console.log("Borrow Rate - Avg CAGR");
    let keys = Object.keys(histogram).sort();
    for (let i = 0; i < keys.length; i++) {
      console.log(
        Utils.formatPercentage(keys[i]) +
          "-" +
          Utils.formatPercentage(
            histogram[keys[i]] / histogramCount[keys[i]],
            2
          )
      );
    }
    return {
      kellyFraction: Utils.round(kellyFraction, 2),
      maxLeverage: Utils.round(maxLeverage, 2),
      minLeverage: Utils.round(minLeverage, 2),
      medianReturn: Utils.formatPercentage(Utils.median(returnArray), 2),
      minEquity: Utils.formatPercentage(globalMinEquity, 2),
      successPercentage: Utils.formatPercentage(
        (totalPeriods - failingPeriods) / totalPeriods
      ),
    };
  }

  async componentDidMount() {
    // Get the data we need
    let loanData = await Utils.textFetch("GET", "/loanRates.csv");
    let sandp = await Utils.textFetch("GET", "/monthlyLargeCapReturns.csv");
    this.setState({
      sp500data: sandp,
      loanData: loanData,
    });
  }

  resolveFractionFormula(lendFraction, borrowFraction) {
    // We should enter short positions that are probably not possible, invest everything in our alternative
    if (lendFraction <= 0) {
      return 0;
    }

    // We should not leverage, and probably invest some in our alternative
    if (lendFraction <= 1) {
      return lendFraction;
    }

    // We should leverage, but borrowing costs are prohibitive
    if (borrowFraction < 1 && lendFraction >= 1) {
      return 1;
    }

    // We should leverage and borrowing costs allow
    return borrowFraction;
  }

  // TODO: This is good for daily data but inefficient for monthly data
  getNextRate(data, rateKey, date) {
    let dateKey = date;
    let result = data[dateKey];

    while (result[rateKey] === undefined) {
      let tomorrow = new Date(dateKey);
      tomorrow.setDate(tomorrow.getDate() + 1);
      dateKey = Utils.formatDate(tomorrow);
      result = data[dateKey];
    }
    return result[rateKey] * 100; // Convert to percentage
  }

  getLastDateIndex(data, rateKey) {
    let allDates = Object.keys(data);
    let dateIndex = allDates.length;
    let result = undefined;
    while (result === undefined || isNaN(result)) {
      dateIndex--;
      let dateKey = allDates[dateIndex];
      result = data[dateKey][rateKey];
    }
    return dateIndex;
  }

  getFirstDateIndex(data, rateKey) {
    let allDates = Object.keys(data);
    let dateIndex = -1;
    let result = undefined;
    while (result === undefined || isNaN(result)) {
      dateIndex++;
      let dateKey = allDates[dateIndex];
      result = data[dateKey][rateKey];
    }
    return dateIndex;
  }

  render() {
    if (this.state.sp500data === null || this.state.loanData === null) {
      return (
        <div style={{ margin: "auto", width: "13%", padding: "20px" }}>
          <Spinner></Spinner>
        </div>
      );
    }
    return (
      <form
        className="rant"
        onSubmit={this.noOp.bind(this)}
        style={{ paddingTop: "0px" }}
      >
        <div>
          <h4>Returns Source</h4>
          <label
            htmlFor="lend-benchmark"
            style={{ marginTop: "3px", marginRight: "3px" }}
          >
            source ={" "}
          </label>
          <select
            name="returns-source"
            id="returns-source"
            value={this.state.returnsSource}
            onChange={this.getFieldUpdateFunction("returnsSource")}
          >
            <option value={"HISTORIC"}>
              Historical S&P500 monthly returns
            </option>
            <option value={"SYNTHETIC"}>
              Synthetic monthly returns (µ=0.009, σ=0.054)
            </option>
          </select>{" "}
          <br></br>
          <h4>Risk Free Rate of Return</h4>
          <label
            htmlFor="lend-benchmark"
            style={{ marginTop: "3px", marginRight: "3px" }}
          >
            rate (r) ={" "}
          </label>
          <select
            name="lend-benchmark"
            id="lend-benchmark"
            value={this.state.lendBenchmark}
            onChange={this.getFieldUpdateFunction("lendBenchmark")}
          >
            <option value={LongTermLeverageConst.FEDFUNDS}>
              Federal Funds Rate (1954-2022)
            </option>
            <option value={LongTermLeverageConst.TRES_1_YEAR}>
              1 Year Treasury (1953-2022)
            </option>
            <option value={LongTermLeverageConst.TRES_2_YEAR}>
              2 Year Treasury (1976-2022)
            </option>
            <option value={LongTermLeverageConst.INTR_GOVT_BOND}>
              Intermediate Govt Bonds (1926-2014)
            </option>
            <option value={LongTermLeverageConst.TBILL}>
              T-Bills (1926-2014)
            </option>
            <option value={LongTermLeverageConst.CASH}>
              Cash (i.e. 0%) (1926-2022)
            </option>
          </select>{" "}
          +{" "}
          <input
            type="string"
            id="lend-benchmark-adj"
            name="lendBenchmarkAdj"
            value={this.state.lendBenchmarkAdj}
            onChange={this.getFieldUpdateFunction("lendBenchmarkAdj")}
          ></input>
          %<br></br>
          <h4>Borrowing Parameters</h4>
          <label
            htmlFor="borrow-benchmark"
            style={{ marginTop: "3px", marginRight: "3px" }}
          >
            rate (b) ={" "}
          </label>
          <select
            name="borrow-benchmark"
            id="borrow-benchmark"
            value={this.state.borrowBenchmark}
            onChange={this.getFieldUpdateFunction("borrowBenchmark")}
          >
            <option value={LongTermLeverageConst.FEDFUNDS}>
              Federal Funds Rate (1954-2022)
            </option>
            <option value={LongTermLeverageConst.TRES_1_YEAR}>
              1 Year Treasury (1953-2022)
            </option>
            <option value={LongTermLeverageConst.TRES_2_YEAR}>
              2 Year Treasury (1976-2022)
            </option>
            <option value={LongTermLeverageConst.INTR_GOVT_BOND}>
              Intermediate Govt Bonds (1926-2014)
            </option>
            <option value={LongTermLeverageConst.TBILL}>
              T-Bills (1926-2014)
            </option>
            <option value={LongTermLeverageConst.CASH}>
              Cash (i.e. 0%) (1926-2022)
            </option>
          </select>{" "}
          +{" "}
          <input
            type="string"
            id="borrow-benchmark-adj"
            name="borrowBenchmarkAdj"
            value={this.state.borrowBenchmarkAdj}
            onChange={this.getFieldUpdateFunction("borrowBenchmarkAdj")}
          ></input>
          %<br></br>
          <label htmlFor="refinancePeriod" style={{ marginTop: "3px" }}>
            Refinance period
          </label>
          <input
            type="number"
            id="refinancePeriod"
            name="refinancePeriod"
            value={this.state.refinancePeriod}
            onChange={this.getFieldUpdateFunction("refinancePeriod")}
            style={{ width: "100px" }}
          />
          <div> day(s)</div>
        </div>
        <div>
          <h4>Leverage Strategy</h4> {this.state.borrowing}
          <div className="form-flex-outer">
            <div className="form-flex-inner">
              <label htmlFor="fractionFormula" style={{ marginTop: "3px" }}>
                f* =
              </label>
              <input
                type="string"
                id="fractionFormula"
                name="fractionFormula"
                value={this.state.fractionFormula}
                onChange={this.getFieldUpdateFunction("fractionFormula")}
                style={{ width: "300px" }}
              />
            </div>
            <div className="form-flex-inner">
              <label htmlFor="rebalancePeriod" style={{ marginTop: "3px" }}>
                Re-balance period
              </label>
              <input
                type="number"
                id="rebalancePeriod"
                name="rebalancePeriod"
                value={this.state.rebalancePeriod}
                onChange={this.getFieldUpdateFunction("rebalancePeriod")}
                style={{ width: "100px" }}
              />
              <div> month(s)</div>
            </div>
          </div>
        </div>
        <div>
          <h4>Definition of "Ruin"</h4>
          <label
            htmlFor="min-equity"
            style={{ marginTop: "3px", marginRight: "3px" }}
          >
            Minimum equity at end of month
          </label>
          <input
            type="string"
            id="min-equity"
            name="minEquity"
            value={this.state.minEquity}
            onChange={this.getFieldUpdateFunction("minEquity")}
          />{" "}
          %
        </div>
        <button onClick={this.runBacktest.bind(this)} class="button">
          Run Backtest
        </button>
        <pre>{this.state.result}</pre>
      </form>
    );
  }
}
export default KellyBacktester;
