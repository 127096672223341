import React from "react";

import { Component } from "react";

import DiaperCalcUtils from "../helpers/baby-diaper-calc/diaper-calc-utils";
import { DiaperBox } from "../helpers/baby-diaper-calc/diaper-box";
import "./baby-diaper-calc.css";

// import Utils from "../helpers/utils";

// TODO(laberle): Allow for existing diapers in calculation.
// TODO(laberle): Pull in percentile data: https://www.who.int/tools/child-growth-standards/standards/weight-for-age
// TODO(laberle): Smaller babies.
// TODO(laberle): Between sizes.

class BabyDiaperCalc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDiaperSize: "2",
      currentWeight: 15.5,
      weightGainPerWeekOunces: 4,
      // TODO Make these configurable
      discountThreshold: 100,
      discountValue: 20,
      salesTax: 0.08445,
      // birthday: new Date("08/15/2021"),
      // sex: "Female",
      // weightPercentile: 30,
      diapersPerDay: 6,
      // https://www.target.com/p/diapers-up-up-select-size-and-count/-/A-79808016
      costData: new Map([
        [
          "N",
          [
            new DiaperBox("N", "Up&Up", 37, 4.79),
            new DiaperBox("N", "Up&Up", 124, 14.99),
          ],
        ],
        [
          "1",
          [
            new DiaperBox("1", "Up&Up", 44, 4.79),
            new DiaperBox("1", "Up&Up", 124, 14.99),
            new DiaperBox("1", "Up&Up", 192, 21.99),
          ],
        ],
        [
          "2",
          [
            new DiaperBox("2", "Up&Up", 37, 4.79),
            new DiaperBox("2", "Up&Up", 120, 14.99),
            new DiaperBox("2", "Up&Up", 184, 21.99),
          ],
        ],
        [
          "3",
          [
            new DiaperBox("3", "Up&Up", 32, 4.79),
            new DiaperBox("3", "Up&Up", 168, 21.99),
            new DiaperBox("3", "Up&Up", 228, 29.99),
          ],
        ],
        [
          "4",
          [
            new DiaperBox("4", "Up&Up", 28, 4.79),
            new DiaperBox("4", "Up&Up", 148, 21.99),
            new DiaperBox("4", "Up&Up", 204, 29.99),
          ],
        ],
        [
          "5",
          [
            new DiaperBox("5", "Up&Up", 24, 4.79),
            new DiaperBox("5", "Up&Up", 128, 21.99),
            new DiaperBox("5", "Up&Up", 186, 29.99),
          ],
        ],
        [
          "6",
          [
            new DiaperBox("6", "Up&Up", 21, 4.79),
            new DiaperBox("6", "Up&Up", 112, 21.99),
            new DiaperBox("6", "Up&Up", 164, 29.99),
          ],
        ],
        ["7", [new DiaperBox("7", "Up&Up", 88, 21.99)]],
      ]),
    };
  }

  fillBag(minDiapersLeftInCurrentSize, maxDiapersLeftInCurrentSize) {
    // TODO - Use DiaperCalcUtils.pickDiapersInCurrentSize
    // let bag = pickDiapersInCurrentSize(
    //   DiaperCalcUtils.getBoxesForSize(this.state.currentDiaperSize),
    //   // TODO - Make this configurable.
    //   (maxDiapersLeftInCurrentSize + minDiapersLeftInCurrentSize) / 2
    // );

    // let bagCost = 0;
    // let diapersOfCurrentSizeAcquired = 0;
    // bag.forEach(bag => {
    //   bagCost += bag.cost;
    //   diapersOfCurrentSizeAcquired += bag.count;
    // });

    let bag = [];
    let bagCost = 0;
    let targetCost = 100;
    let diapersOfCurrentSizeAcquired = 0;
    // TODO - Make this configurable.
    let maxDiapersInCurrentSize =
      (maxDiapersLeftInCurrentSize + minDiapersLeftInCurrentSize) / 2;
    // TODO: Is this size cheaper per diaper than next size up?
    while (diapersOfCurrentSizeAcquired < maxDiapersInCurrentSize) {
      let newBox = DiaperCalcUtils.findLeastExpensivePerDiaperBoxDeprecated(
        this.state.currentDiaperSize,
        this.state.costData
      );
      if (
        newBox.count + diapersOfCurrentSizeAcquired >=
          maxDiapersInCurrentSize ||
        bagCost + newBox.cost > targetCost
      ) {
        // TODO Buy something else.
        bag = this.blindlyFillBag(
          bag,
          DiaperCalcUtils.incrementDiaperSize(this.state.currentDiaperSize),
          targetCost - bagCost
        );
        break;
      }

      bag.push(newBox);
      diapersOfCurrentSizeAcquired += newBox.count;
      bagCost += newBox.cost;
    }
    this.setState({
      bag: bag,
    });
  }

  blindlyFillBag(bag, size, targetMoneyToSpend) {
    let newBox = DiaperCalcUtils.findLeastExpensivePerDiaperBoxDeprecated(
      size,
      this.state.costData
    );
    while (targetMoneyToSpend > 0) {
      if (targetMoneyToSpend - newBox.cost < 0) {
        // See if we can sub in a smaller pack for a good deal.
        bag.push(this.buyCheapestBox(size, targetMoneyToSpend));
        return bag;
      }
      bag.push(newBox);
      targetMoneyToSpend -= newBox.cost;
    }
    return bag;
  }

  buyCheapestBox(size, targetMoneyToSpend) {
    let allBoxesThisSize = this.state.costData.get(size);
    let boxesSortedByCost = allBoxesThisSize.sort((a, b) =>
      a.cost > b.cost ? 1 : -1
    );
    console.log(boxesSortedByCost);
    let properBox = boxesSortedByCost[0];
    // TODO - This is not necessarily the most optimal box.
    for (const box of boxesSortedByCost) {
      if (box.cost >= targetMoneyToSpend) {
        properBox = box;
        break;
      }
    }
    return properBox;
  }

  handleInputChange(nameOfField) {
    return function setStateForField(value) {
      let myNewState = {};
      myNewState[nameOfField] = value;
      this.setState(myNewState);
      // TODO - Make this more React-ey;
    }.bind(this);
  }

  render() {
    let minNumOfWeeksLeftInSize = DiaperCalcUtils.estimateMinNumOfWeeksLeftInSize(
      this.state.currentDiaperSize,
      this.state.weightGainPerWeekOunces,
      this.state.currentWeight
    );
    let minDiapersLeftInCurrentSize = DiaperCalcUtils.calculateNumDiapersLeftInSize(
      minNumOfWeeksLeftInSize,
      this.state.diapersPerDay
    );

    let maxNumOfWeeksLeftInSize = DiaperCalcUtils.estimateMaxNumOfWeeksLeftInSize(
      this.state.currentDiaperSize,
      this.state.weightGainPerWeekOunces,
      this.state.currentWeight
    );

    let maxDiapersLeftInCurrentSize = DiaperCalcUtils.calculateNumDiapersLeftInSize(
      maxNumOfWeeksLeftInSize,
      this.state.diapersPerDay
    );

    return (
      <div>
        <div className="inputCard">
          <div className="inputRow">
            <label for="currentWeight">Current Weight (lbs)</label>
            {/* TODO - Ask for ounces too */}
            <input
              className="input"
              value={this.state.currentWeight}
              id="currentWeight"
              type="number"
              min="0"
              max="50"
              onChange={e =>
                this.handleInputChange("currentWeight")(e.target.value)
              }
            />
          </div>
          <div className="inputRow">
            <label for="currentDiaperSize">Current Diaper Size</label>
            {/* TODO - Validate this input */}
            <input
              className="input diaperSizeInput"
              value={this.state.currentDiaperSize}
              id="currentDiaperSize"
              onChange={e =>
                this.handleInputChange("currentDiaperSize")(e.target.value)
              }
            />
          </div>
          <div className="inputRow">
            <label for="diapersPerDay">Avg Diapers Per Day</label>
            <input
              className="input numberInput"
              value={this.state.diapersPerDay}
              id="diapersPerDay"
              type="number"
              min="0"
              max="20"
              onChange={e =>
                this.handleInputChange("diapersPerDay")(e.target.value)
              }
            />
          </div>
          <div className="inputRow">
            <label for="weightGain">Avg Weight Gain Per Week (oz)</label>
            <input
              className="input numberInput"
              value={this.state.weightGainPerWeekOunces}
              id="weightGain"
              type="number"
              min="0"
              max="100"
              onChange={e =>
                this.handleInputChange("weightGainPerWeekOunces")(
                  e.target.value
                )
              }
            />
          </div>
        </div>
        <div>Min Weeks Left: {+minNumOfWeeksLeftInSize.toFixed(2)}</div>
        <div>Max Weeks Left: {+maxNumOfWeeksLeftInSize.toFixed(2)}</div>
        <div>
          Min Diapers Left In This Size:{" "}
          {+minDiapersLeftInCurrentSize.toFixed(2)}
        </div>
        <div>
          Max Diapers Left In This Size:{" "}
          {+maxDiapersLeftInCurrentSize.toFixed(2)}
        </div>

        <button
          className="button"
          onClick={this.fillBag.bind(
            this,
            minDiapersLeftInCurrentSize,
            maxDiapersLeftInCurrentSize
          )}
        >
          Fill Shopping Bag
        </button>

        <div>
          You should buy:
          <ShoppingBag
            bag={this.state.bag}
            salesTax={this.state.salesTax}
            discount={this.state.discountValue}
          />
        </div>
      </div>
    );
  }
}

function ShoppingBag(props) {
  if (!props.bag) {
    return null;
  }

  let subtotal = 0;
  let totalDiapers = 0;
  let sizeCountMap = new Map();
  props.bag.forEach(box => {
    subtotal += box.cost;
    if (sizeCountMap.has(box.size)) {
      let currentCount = sizeCountMap.get(box.size);
      sizeCountMap.set(box.size, currentCount + box.count);
    } else {
      sizeCountMap.set(box.size, box.count);
    }
    totalDiapers += box.count;
  });

  let tax = subtotal * props.salesTax;
  let totalCost = subtotal + tax - props.discount;
  let discountPercentage = (subtotal - totalCost) / subtotal;

  // let discountSanityCheck = 0;
  // props.bag.forEach(box => {
  //   discountSanityCheck +=
  //     box.costPerDiaper * (1 - discountPercentage) * box.count;
  // });
  // console.log(discountSanityCheck);

  let diapersDisplay = [];
  sizeCountMap.forEach((count, size) => {
    // TODO - Brand matters here if we do that.
    diapersDisplay.push(
      <li>
        Size {size}: {count}
      </li>
    );
  });

  // TODO How long will this last me?
  return (
    <div>
      <div>
        Diapers:
        <ul>{diapersDisplay}</ul>
      </div>
      <ol>
        {props.bag.map(box => (
          <li>
            Size: {box.size} Count: {box.count}
            <ul>
              <li>
                Discounted Cost Per Diaper:{" "}
                {(box.costPerDiaper * 100 * (1 - discountPercentage)).toFixed(
                  3
                )}{" "}
                cents
              </li>
            </ul>
          </li>
        ))}
      </ol>
      <div>Subtotal: ${subtotal.toFixed(2)} </div>
      <div>Tax: ${tax.toFixed(2)} </div>
      <div>
        Total after ${props.discount} discount: ${totalCost.toFixed(2)}{" "}
      </div>
      <div>
        Average cost per diaper: {((totalCost / totalDiapers) * 100).toFixed(3)}{" "}
        cents
      </div>
      <div>Discount after tax: {(discountPercentage * 100).toFixed(3)} %</div>
    </div>
  );
}

export default BabyDiaperCalc;
