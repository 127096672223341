import React from "react";

class FixedIframe extends React.Component {
  constructor(props) {
    super(props);
    this.state = { overlayVisible: true };
  }
  componentDidMount() {
    const $iframe = document.querySelector("iframe");
    const $body = document.body;

    const targetFrame = document.getElementById("game");
    const overlay = document.getElementById("overlay");

    window.addEventListener("click", event => {
      console.log("CLICK ", event.target === overlay);
      if (event.target === overlay) {
        this.setState({ overlayVisible: false });
        targetFrame.focus();
        $body.style.overflow = "hidden";
      } else {
        this.setState({ overlayVisible: true });
        $body.style.overflow = "visible";
      }
    });
  }

  render() {
    return (
      <div id="loading" style={{ ...this.props.style, position: "relative" }}>
        <div
          id="overlay"
          style={{
            backgroundColor: "black",
            opacity: ".3",
            position: "absolute",
            top: "0",
            left: "0",
            width: "1296px",
            height: "740px",
            visibility: this.state.overlayVisible ? "visible" : "hidden",
          }}
        ></div>
        <iframe
          id="game"
          src="https://thbrown.github.io/spaceship-resurrection/"
          width="1296"
          height="740"
          title="Spaceship Resurrection"
        ></iframe>
      </div>
    );
  }
}

export default FixedIframe;
