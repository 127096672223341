import React from "react";

import { Component } from "react";

import Utils from "../helpers/utils";

import sha256 from "crypto-js/sha256";
import Base64 from "crypto-js/enc-base64";

import KaleidoscopeColoring from "../components/kaleidoscope-coloring";

class KaleidoscopeColoringSheet extends Component {
  constructor(props) {
    super(props);
    this.STARTING_STATE = {
      value: "ENDLESSWIPS",
      color: false,
      quality: 1,
    };
    this.state = this.STARTING_STATE;
  }

  getFieldUpdateFunction(fieldName) {
    return function (e) {
      let stateUpdate = {};
      if (e.target.type === "checkbox") {
        stateUpdate[fieldName] = e.target.checked;
      } else {
        stateUpdate[fieldName] = e.target.value;
      }
      this.setState(stateUpdate);
    }.bind(this);
  }

  // TODO: use ref!
  downloadCanvas() {
    console.log("Download");
    let link = document.createElement("a");
    link.download = `pattern.png`;
    link.href = document.getElementById("canvas").toDataURL();
    link.click();
  }

  async onMountOrUpdate() {}

  async componentDidMount() {
    this.onMountOrUpdate();
  }

  async componentDidUpdate() {
    this.onMountOrUpdate();
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <input
            type="string"
            onChange={this.getFieldUpdateFunction("value")}
            placeholder={"Pattern Code"}
          ></input>
          <div style={{ display: "flex" }}>
            <input
              id="quality"
              type="range"
              min="1"
              max="6"
              onChange={this.getFieldUpdateFunction("quality")}
              value={this.state.quality}
            ></input>
            <label
              for="quality"
              style={{
                padding: "5px",
              }}
            >
              {" "}
              Image Quality
            </label>
          </div>
          <div>
            <input
              type="checkbox"
              id="color"
              name="color"
              onChange={this.getFieldUpdateFunction("color")}
              //checked={this.state.color}
            ></input>
            <label for="color"> Color it for me</label>
          </div>
        </div>
        <div
          style={{
            marginLeft: this.props.padding,
          }}
        >
          <div>
            <KaleidoscopeColoring
              variant={null}
              hash={Base64.stringify(sha256(this.state.value))}
              quality={this.state.quality}
              color={this.state.color}
              key={sha256(this.state.quality)} // Re-make the canvas when the quality changes
            ></KaleidoscopeColoring>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "4px",
            }}
          >
            <button class="button" onClick={this.downloadCanvas}>
              Download Pattern Image
            </button>
          </div>
        </div>
      </div>
    );
  }
}
export default KaleidoscopeColoringSheet;
