import React from "react";

import { Component } from "react";

class InlineImage extends Component {
  componentDidMount() {}

  render() {
    console.log(window.screen.availWidth, this.props.width);
    const EXTRA_MARGIN = 100;
    let flexWrap =
      this.props.width &&
      window.screen.availWidth > this.props.width + EXTRA_MARGIN
        ? null
        : "wrap";

    if (this.props.direction === "left") {
      return (
        <div style={{ display: "flex", padding: "8px", flexWrap: flexWrap }}>
          <img
            alt={this.props.alt}
            src={this.props.img}
            alt="Series I bonds"
            width={this.props.width}
            height={this.props.height}
          ></img>
          <div style={{ paddingLeft: "5px" }}>{this.props.children}</div>
        </div>
      );
    } else if (
      this.props.direction === "right" ||
      this.props.direction === undefined
    ) {
      return (
        <div style={{ display: "flex", padding: "5px", flexWrap: flexWrap }}>
          <div style={{ paddingRight: "5px" }}>{this.props.children}</div>
          <img
            alt={this.props.alt}
            style={{
              marginLeft: "auto",
              marginRight: 0,
            }}
            src={this.props.img}
            alt="Series I bonds"
            width={this.props.width}
            height={this.props.height}
          ></img>
        </div>
      );
    } else {
      return <div>INVALID DIRECTION</div>;
    }
  }
}
export default InlineImage;
