class LongTermLeverageConst {
  static FEDFUNDS = "FEDFUNDS";
  static TRES_1_YEAR = "GS1";
  static TRES_2_YEAR = "GS2";
  static INTR_GOVT_BOND = "INTGOVBND";
  static CASH = "CASH";
  static TBILL = "TBILL";
}

module.exports = LongTermLeverageConst;
