export class DiaperBox {
  size: string;
  brand: string;
  count: number;
  cost: number;
  costPerDiaper: number;

  constructor(size: string, brand: string, count: number, cost: number) {
    this.size = size;
    this.brand = brand;
    this.count = count;
    this.cost = cost;
    this.costPerDiaper = cost / count;
  }

  toString() {
    return `DiaperBox[size=${this.size} count=${this.count} costPerDiaper=${this.costPerDiaper}]`;
  }

  get [Symbol.toStringTag]() {
    return this.toString();
  }
}
