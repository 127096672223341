export class DiaperBoxSize {
  size: string;
  minWeight: number;
  maxWeight: number;

  constructor(size: string, minWeight: number, maxWeight: number) {
    this.size = size;
    this.minWeight = minWeight;
    this.maxWeight = maxWeight;
  }
}
