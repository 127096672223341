import type { DiaperBox } from "./diaper-box";
import { DiaperBoxSize } from "./diaper-box-size";

export default class DiaperCalcUtils {
  static DIAPER_SIZES: Map<string, DiaperBoxSize> = new Map([
    // Size, Min Weight, Max Weight
    ["N", new DiaperBoxSize("N", 0, 10)],
    ["1", new DiaperBoxSize("1", 8, 14)],
    ["2", new DiaperBoxSize("2", 12, 18)],
    ["3", new DiaperBoxSize("3", 16, 28)],
    ["4", new DiaperBoxSize("4", 22, 37)],
    ["5", new DiaperBoxSize("5", 27, 35)],
    ["6", new DiaperBoxSize("6", 35, 41)],
    ["7", new DiaperBoxSize("7", 41, 60)],
  ]);

  static incrementDiaperSize(size: string): string {
    let nextDiaperSize = "1";
    if (size !== "N") {
      let sizeInt = parseInt(size);
      if (sizeInt >= 7) {
        throw new Error("Cannot increment size 7 - no diapers that big!");
      }
      nextDiaperSize = String(sizeInt + 1);
    }
    return nextDiaperSize;
  }

  static findLeastExpensivePerDiaperBoxDeprecated(
    size: string,
    costData: Map<string, Array<DiaperBox>>
  ): DiaperBox {
    if (!costData.has(size)) {
      throw new Error("Size " + size + " not found in costData!");
    }
    const boxes: Array<DiaperBox> = costData.get(size)!;
    let leastExpensive = 10000; // Big number.
    let leastExpensiveIndex = 0;
    boxes.forEach((box, i) => {
      if (box.costPerDiaper < leastExpensive) {
        leastExpensive = box.costPerDiaper;
        leastExpensiveIndex = i;
      }
    });
    return boxes[leastExpensiveIndex];
  }

  static getBoxesForSize(
    size: string,
    costData: Map<string, Array<DiaperBox>>
  ) {
    if (!costData.has(size)) {
      throw new Error("Size " + size + " not found in costData!");
    }
    return costData.get(size)!;
  }

  static findLeastExpensivePerDiaper(boxes: Array<DiaperBox>): DiaperBox {
    let leastExpensive = 10000; // Big number.
    let leastExpensiveIndex = 0;
    boxes.forEach((box, i) => {
      if (box.costPerDiaper < leastExpensive) {
        leastExpensive = box.costPerDiaper;
        leastExpensiveIndex = i;
      }
    });
    return boxes[leastExpensiveIndex];
  }

  static calculateNumDiapersLeftInSize(
    numWeeksLeftInSize: number,
    diapersPerDay: number
  ): number {
    return numWeeksLeftInSize * 7 * diapersPerDay;
  }

  static estimateMinNumOfWeeksLeftInSize(
    size: string,
    weightGainPerWeekOunces: number,
    currentWeight: number
  ): number {
    if (!size) {
      return 0;
    }
    // TODO(laberle): Pull in percentile data: https://www.who.int/tools/child-growth-standards/standards/weight-for-age
    let weightGainPerWeek = weightGainPerWeekOunces / 16;
    let nextDiaperSize = DiaperCalcUtils.incrementDiaperSize(size);
    let minWeightForNextSize = DiaperCalcUtils.DIAPER_SIZES.get(nextDiaperSize)!
      .minWeight;
    return (minWeightForNextSize - currentWeight) / weightGainPerWeek;
  }

  static estimateMaxNumOfWeeksLeftInSize(
    size: string,
    weightGainPerWeekOunces: number,
    currentWeight: number
  ): number {
    if (!size) {
      return 0;
    }

    // TODO(laberle): Pull in percentile data: https://www.who.int/tools/child-growth-standards/standards/weight-for-age
    const weightGainPerWeek = weightGainPerWeekOunces / 16; // 4 oz/week
    const maxWeightBeforeNextSize = DiaperCalcUtils.DIAPER_SIZES.get(size)!
      .maxWeight;
    return (maxWeightBeforeNextSize - currentWeight) / weightGainPerWeek;
  }

  // TODO - Remove this probably?
  // static pickMinDiapersInCurrentSize(
  //   boxes: Array<DiaperBox>,
  //   minDiapers: number
  // ): Array<DiaperBox> {
  //   const box = DiaperCalcUtils.findLeastExpensivePerDiaper(boxes);
  //   if (box.count <= minDiapers) {
  //     let numBoxes = Math.ceil(minDiapers / box.count);
  //     return Array(numBoxes).fill(box);
  //   }
  //   // Need fewer than the biggest box has. Remove the too big box and try again.
  //   boxes = boxes.filter(item => item !== box);
  //   return DiaperCalcUtils.pickMinDiapersInCurrentSize(boxes, minDiapers);
  // }

  static pickDiapersInCurrentSize(
    boxes: Array<DiaperBox>,
    minDiapers: number,
    maxDiapers: number
  ): Array<DiaperBox> {
    let pickedBoxes: Array<DiaperBox> = [];
    const leastExpensivePerDiaperBox = DiaperCalcUtils.findLeastExpensivePerDiaper(
      boxes
    );

    // Need fewer than the biggest box has. Remove the too big box and try again.
    if (leastExpensivePerDiaperBox.count > maxDiapers) {
      boxes = boxes.filter(item => item !== leastExpensivePerDiaperBox);
      return DiaperCalcUtils.pickDiapersInCurrentSize(
        boxes,
        minDiapers,
        maxDiapers
      );
    }

    let numDiapers = 0;
    while (numDiapers < maxDiapers) {
      pickedBoxes.push(leastExpensivePerDiaperBox);
      numDiapers += leastExpensivePerDiaperBox.count;
      if (numDiapers >= minDiapers) {
        break;
      }
    }
    return pickedBoxes;
  }

  static calculateCostOfBoxes(boxes: Array<DiaperBox>): number {
    if (boxes.length === 0) {
      return 0;
    }
    return boxes.map(box => box.cost).reduce((prev, next) => prev + next);
  }

  static shopForDiapers(
    startingSize: string,
    minDiapersInStartingSize: number,
    maxDiapersInStartingSize: number,
    minCost: number,
    costData: Map<string, Array<DiaperBox>>
  ): Array<DiaperBox> {
    let size = startingSize;
    let bag: Array<DiaperBox> = [];
    while (true) {
      bag = bag.concat(
        DiaperCalcUtils.pickDiapersInCurrentSize(
          DiaperCalcUtils.getBoxesForSize(size, costData),
          minDiapersInStartingSize,
          maxDiapersInStartingSize
        )
      );
      if (DiaperCalcUtils.calculateCostOfBoxes(bag) > minCost) {
        // TODO - Do cost optimization here on last size.
        break;
      }
      // Buy more diapers.
      // TODO - Change target num of diapers for next size up.
      // TODO - Allow for different additional size (2 kids).
      size = DiaperCalcUtils.incrementDiaperSize(size);
    }
    // TODO - Adjust last size in bag to make it more cost efficient.
    return bag;
  }
}
