import React from "react";

import { Component } from "react";
import { textChangeRangeIsUnchanged } from "typescript";

import Utils from "../helpers/utils";

class KellyCriterionTester extends Component {
  constructor(props) {
    super(props);
    this.commentSubmitForm = React.createRef();

    this.STARTING_STATE = {
      bankRoll: 100,
      winProbability: 0.6,
      lossFraction: 1,
      winFraction: 1,
      betFormula: "m*(p/a-(1-p)/b)", // Kelly
      currentMoney: 100,
      wins: 0,
      losses: 0,
      bets: 0,
      log: [],
    };

    this.state = this.STARTING_STATE;
  }

  getFieldUpdateFunction(fieldName) {
    return function (e) {
      let stateUpdate = {};
      if (e.target.type === "checkbox") {
        stateUpdate[fieldName] = e.target.checked;
      } else {
        stateUpdate[fieldName] = e.target.value;
      }
      this.setState(stateUpdate);
    }.bind(this);
  }

  getBetFunction(n) {
    return function () {
      let newCurrentMoney = this.state.currentMoney;
      let newBets = this.state.bets;
      let newWins = this.state.wins;
      let newLosses = this.state.losses;
      let newLog = [...this.state.log];

      for (let i = 0; i < n; i++) {
        let win = Utils.cryptoRandom() < this.state.winProbability;
        let bet = this.evaluateFormula(this.state.betFormula, newCurrentMoney);

        if (bet < 0.01) {
          newLog.push({
            message: `INVALID BET: can't be less than $.01 was ${Utils.formatMoney(
              bet
            )}`,
            color: "yellow",
          });
          break;
        }

        if (!this.state.borrowing && bet > newCurrentMoney) {
          newLog.push({
            message: `INVALID BET: your bet (${Utils.formatMoney(
              bet
            )}) is larger than the money you have ${Utils.formatMoney(
              newCurrentMoney
            )}`,
            color: "yellow",
          });
          break;
        }

        let change = 0;
        if (win) {
          change = bet * this.state.winFraction;
          newWins++;
        } else {
          change = -bet * this.state.lossFraction;
          newLosses++;
        }
        newBets++;
        newCurrentMoney = Utils.round(newCurrentMoney + change, 2);

        if (newCurrentMoney < 0.01) {
          newLog.push({
            message: `TOTAL LOSS`,
            color: "red",
          });
          break;
        }

        newLog.push({
          message: `${newBets}) ${win ? "WON  " : "LOST"} ${Utils.formatMoney(
            change
          )} Total: ${Utils.formatMoney(newCurrentMoney)}`,
          color: win ? "#80ff80" : "#FFBFBF",
        });
      }

      this.setState({
        currentMoney: newCurrentMoney,
        bets: newBets,
        wins: newWins,
        losses: newLosses,
        log: newLog,
      });

      // Update the state after running all
    }.bind(this);
  }

  resetSimulation() {
    this.setState(this.STARTING_STATE);
  }

  resetMoney() {
    this.setState({
      currentMoney: this.state.bankRoll,
      bets: 0,
      wins: 0,
      losses: 0,
      log: [],
    });
  }

  noOp(e) {
    e.preventDefault();
  }

  evaluateFormula(formula, availableMoney, allowLeverage) {
    try {
      formula = formula.replaceAll("m", availableMoney);
      formula = formula.replaceAll("p", this.state.winProbability);
      formula = formula.replaceAll("a", this.state.lossFraction);
      formula = formula.replaceAll("b", this.state.winFraction);
      return Utils.round(eval(formula), 2);
    } catch (e) {
      console.log(e);
      return e;
    }
  }

  componentDidUpdate() {
    // Keep the console output scrolled to the bottom
    let element = document.getElementById("console");
    element.scrollTop = element.scrollHeight;
  }

  render() {
    let evaluatedFormula = undefined;
    try {
      evaluatedFormula = this.evaluateFormula(
        this.state.betFormula,
        this.state.currentMoney
      );
    } catch (e) {
      evaluatedFormula = "ERROR:" + e.message;
    }

    let logOutput = [];
    for (let row of this.state.log) {
      console.log(row.message);
      logOutput.push(
        <pre
          style={{
            backgroundColor: row.color,
            padding: "0px",
          }}
        >
          {row.message}
        </pre>
      );
    }
    return (
      <form
        className="rant"
        onSubmit={this.noOp.bind(this)}
        style={{ paddingTop: "0px" }}
      >
        <h4>Situation Parameters</h4> {this.state.borrowing}
        <div className="form-flex-outer">
          <div className="form-flex-inner">
            <label htmlFor="bank-roll" style={{ marginTop: "3px" }}>
              Starting Money (m)
            </label>
            <input
              type="number"
              id="bank-roll"
              name="bankRoll"
              value={this.state.bankRoll}
              onChange={this.getFieldUpdateFunction("bankRoll")}
              style={{ width: "100px" }}
            />
          </div>
          <div className="form-flex-inner">
            <label htmlFor="win-probability" style={{ marginTop: "3px" }}>
              Win Probability (p)
            </label>
            <input
              type="number"
              id="win-probability"
              name="winProbability"
              value={this.state.winProbability}
              onChange={this.getFieldUpdateFunction("winProbability")}
              style={{ width: "100px" }}
            />
          </div>
          <div className="form-flex-inner">
            <label htmlFor="loss-fraction" style={{ marginTop: "3px" }}>
              Loss Fraction (a)
            </label>
            <input
              type="number"
              id="loss-fraction"
              name="lossFraction"
              value={this.state.lossFraction}
              onChange={this.getFieldUpdateFunction("lossFraction")}
              style={{ width: "100px" }}
            />
          </div>
          <div className="form-flex-inner">
            <label htmlFor="win-fraction" style={{ marginTop: "3px" }}>
              Win Fraction (b)
            </label>
            <input
              type="number"
              id="win-fraction"
              name="winFraction"
              value={this.state.winFraction}
              onChange={this.getFieldUpdateFunction("winFraction")}
              style={{ width: "100px" }}
            />
          </div>
          <div className="form-flex-inner">
            <label htmlFor="borrowing" style={{ marginTop: "3px" }}>
              Allow Leverage
            </label>
            <input
              type="checkbox"
              id="borrowing"
              name="borrowing"
              value={this.state.borrowing}
              onChange={this.getFieldUpdateFunction("borrowing")}
              style={{ width: "100px", width: "122px", marginTop: "10px" }}
            />
          </div>
        </div>
        <h4>Output</h4>
        <div
          style={
            {
              //display: "flex",
              //flexWrap: "wrap",
              //justifyContent: "space-between",
            }
          }
        >
          <div>
            Current Money: <b>{Utils.formatMoney(this.state.currentMoney)}</b>
          </div>
          <div>
            Wins:{" "}
            <b>
              {this.state.wins} (
              {Utils.formatPercentage(
                this.state.wins / (this.state.wins + this.state.losses)
              )}
              )
            </b>
          </div>
          <div>
            Losses:{" "}
            <b>
              {this.state.losses} (
              {Utils.formatPercentage(
                this.state.losses / (this.state.wins + this.state.losses)
              )}
              )
            </b>
          </div>
          <div>
            Total Bets: <b>{this.state.bets}</b>
          </div>
          <div>
            Gains per Bet:{" "}
            <b>
              {Utils.formatMoney(
                (this.state.currentMoney - this.state.bankRoll) /
                  this.state.bets
              )}
            </b>
          </div>
        </div>
        <div
          id="console"
          className="input"
          style={{ overflow: "auto", height: "150px", textAlign: "left" }}
        >
          {logOutput}
        </div>
        <h4>Controls</h4>
        <div>
          <label
            htmlFor="win-fraction"
            style={{ marginTop: "3px", marginRight: "3px" }}
          >
            Bet Formula{" "}
          </label>
          <input
            type="string"
            id="bet-formula"
            name="betFormula"
            value={this.state.betFormula}
            onChange={this.getFieldUpdateFunction("betFormula")}
          />
          <div style={{ display: "flex", marginTop: "3px" }}>
            <label
              htmlFor="bet-value"
              style={{ marginTop: "3px", marginRight: "3px" }}
            >
              Bet Value{" "}
            </label>
            <div
              id="win-value"
              style={{ marginTop: "3px", marginLeft: "21px" }}
            >
              <b>
                {evaluatedFormula <= this.state.currentMoney
                  ? Utils.formatMoney(evaluatedFormula)
                  : Utils.formatMoney(this.state.currentMoney) +
                    " (" +
                    Utils.formatMoney(evaluatedFormula) +
                    " w/ leverage)"}
              </b>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", marginTop: "6px" }}>
          <button
            className="button-lite"
            style={{ width: "90px", height: "52px", marginLeft: "4px" }}
            onClick={this.getBetFunction(1)}
          >
            Bet 1 time
          </button>
          <button
            className="button-lite"
            style={{ width: "90px", height: "52px", marginLeft: "4px" }}
            onClick={this.getBetFunction(10)}
          >
            Bet 10 times
          </button>
          <button
            className="button-lite"
            style={{ width: "90px", height: "52px", marginLeft: "4px" }}
            onClick={this.getBetFunction(100)}
          >
            Bet 100 times
          </button>
        </div>
        <h4>Reset</h4>
        <div>
          {" "}
          <button
            className="button"
            style={{
              width: "133px",
              height: "52px",
              marginLeft: "4px",
              marginTop: "4px",
            }}
            onClick={this.resetMoney.bind(this)}
          >
            Reset Money/Bets
          </button>
          <button
            className="button"
            style={{
              width: "133px",
              height: "52px",
              marginLeft: "4px",
              marginTop: "4px",
            }}
            onClick={this.resetSimulation.bind(this)}
          >
            Reset Everything
          </button>
        </div>
      </form>
    );
  }
}
export default KellyCriterionTester;
