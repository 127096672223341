export default class FiCalcUtils {
  static calculateYearsToRetirement(
    swr: number,
    returnOnInvestment: number,
    expenses: number,
    annualSavings: number,
    savings: number,
    todayExtraExpense: number
  ): number {
    let moneyToRetire = FiCalcUtils.calculateMoneyToRetire(swr, expenses);
    return FiCalcUtils.nper(
      returnOnInvestment / 100.0 /* netRate */,
      -annualSavings /* paymentAmount */,
      -(savings - todayExtraExpense) /* presentValue */,
      moneyToRetire /* futureValue */
    );
  }

  static nper(
    rate: number,
    paymentAmount: number,
    presentValue: number,
    futureValue: number
  ) {
    if (rate === 0) {
      // presentValue and paymentAmount are inverted because nper is traditionally drawn down,
      // not accumulated up.
      // We need to uninvert them here.
      return (futureValue - presentValue * -1.0) / (paymentAmount * -1.0);
    }

    const endOrBeginning: number = 0;
    const numerator: number =
      paymentAmount * (1 + rate * endOrBeginning) - futureValue * rate;
    const denominator =
      presentValue * rate + paymentAmount * (1 + rate * endOrBeginning);
    return Math.log(numerator / denominator) / Math.log(1 + rate);
  }

  static calculateMoneyToRetire(swr: number, expenses: number) {
    return expenses / (swr / 100.0);
  }

  static formatYears(years: number): string {
    return isNaN(years) || !isFinite(years)
      ? "With these parameters, you will never reach FI!"
      : years.toFixed(2);
  }

  static secondsToFormattedTimeString(seconds: number): string {
    if (isNaN(seconds)) {
      return "With these parameters, you will never reach FI!";
    }

    if (seconds === 0) {
      return "0 seconds";
    }

    let numYears;
    let numDays;
    let numHours;
    let numMinutes;
    let numSeconds;
    if (seconds > 0) {
      numYears = Math.floor(seconds / 31536000);
      numDays = Math.floor((seconds % 31536000) / 86400);
      numHours = Math.floor(((seconds % 31536000) % 86400) / 3600);
      numMinutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
      numSeconds = (((seconds % 31536000) % 86400) % 3600) % 60;
    } else {
      numYears = Math.ceil(seconds / 31536000);
      numDays = Math.ceil((seconds % 31536000) / 86400);
      numHours = Math.ceil(((seconds % 31536000) % 86400) / 3600);
      numMinutes = Math.ceil((((seconds % 31536000) % 86400) % 3600) / 60);
      numSeconds = (((seconds % 31536000) % 86400) % 3600) % 60;
    }

    return (
      (numYears == 0 ? "" : numYears + " years ") +
      (numDays == 0 ? "" : numDays + " days ") +
      (numHours == 0 ? "" : numHours + " hours ") +
      (numMinutes == 0 ? "" : numMinutes + " minutes and ") +
      (numSeconds == 0 ? "" : numSeconds.toFixed(0) + " seconds")
    );
  }
}
