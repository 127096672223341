import React, { ChangeEvent } from "react";
import Utils from "../helpers/utils";

class Market {
  constructor(
    startingMarketCap,
    numInvestments,
    numActiveInvestors,
    percentActiveFunds,
    investorQuality
  ) {
    // Init investments
    this.possibleInvestments = {};
    for (let i = 0; i < numInvestments; i++) {
      this.possibleInvestments[i] = new Investment(
        i,
        startingMarketCap / numInvestments,
        startingMarketCap / numInvestments
      );
    }

    // Init active investors
    let activeAsPercentage = percentActiveFunds / 100;
    let moneyPerActiveInvestor =
      (activeAsPercentage * startingMarketCap) / numActiveInvestors;
    this.actives = [];
    for (let i = 0; i < numActiveInvestors; i++) {
      this.actives.push(
        new ActiveInvestor(
          moneyPerActiveInvestor,
          this.possibleInvestments,
          investorQuality
        )
      );
    }

    // Init passive investor
    let passiveMoney = activeAsPercentage * startingMarketCap;
    this.passive = new Investor(passiveMoney, this.possibleInvestments);
  }

  allocateCapital() {
    this.passive.allocateCapital();
    for (let active of this.actives) {
      active.allocateCapital();
    }
  }

  growInvestments(avgAnnualMarketGrowth, avgAnnualMarketStdev) {
    const dailyAvgGrowth = avgAnnualMarketGrowth / 252; // TODO: total days? or trading days?
    const dailyAvgStdDec = avgAnnualMarketStdev / Math.sqrt(252);

    for (let investmentName in this.possibleInvestments) {
      let investment = this.possibleInvestments[investmentName];
      investment.actualValue += Utils.randomFromNormal(
        dailyAvgGrowth,
        dailyAvgStdDec
      );
    }
  }

  updateMarketPrices(increasePercentage = 0) {
    // Find the percentage of shares issued by each investment
    let totalPortfolio = {};
    for (let investmentName in this.possibleInvestments) {
      totalPortfolio[investmentName] = this.passive.portfolio[investmentName];
      for (let activeInvestor of this.actives) {
        totalPortfolio[investmentName] +=
          activeInvestor.portfolio[investmentName];
      }
    }

    // Find the market value of all those shares (with some increase - inflation/more investment?)
    let totalShares = 0;
    let totalMarketValue = 0;
    for (let investmentName in totalPortfolio) {
      let investment = this.possibleInvestments[investmentName];
      totalShares += totalPortfolio[investmentName];
      totalMarketValue =
        totalPortfolio[investmentName] * investment.marketValue;
    }

    totalMarketValue = totalMarketValue * (increasePercentage + 1);
    const valuePerShare = totalMarketValue / totalShares;

    // Adjust the prices
    for (let investmentName in totalPortfolio) {
      let investment = this.possibleInvestments[investmentName];
      investment.marketValue =
        totalMarketValue / (totalPortfolio[investmentName] / totalShares);
    }
  }

  // totalValue = x * shareCount

  getPassiveCAGR(elapsedDays) {
    return Utils.CAGR(
      this.passive.startingInvestment,
      this.passive.getPortfolioMarketValue(),
      elapsedDays / 365
    );
  }

  getActiveCAGRS(elapsedDays) {
    let CAGRS = [];
    for (let investor of this.actives) {
      CAGRS.push(
        Utils.CAGR(
          investor.startingInvestment,
          investor.getPortfolioMarketValue(),
          elapsedDays / 365
        )
      );
    }
    return CAGRS;
  }
}

class Investor {
  constructor(startingInvestment, possibleInvestments) {
    this.possibleInvestments = possibleInvestments;
    this.startingInvestment = startingInvestment;
    this.portfolio = {};
  }

  allocateCapital() {
    let portfolioMarketValue =
      Object.keys(this.portfolio).length === 0
        ? this.startingInvestment
        : this.getPortfolioMarketValue();

    // TODO: this could be cached for perf
    let totalMarketSharePrices = 0;
    for (let investmentName in this.possibleInvestments) {
      let investment = this.possibleInvestments[investmentName];
      totalMarketSharePrices += investment.marketValue;
    }

    for (let investmentName in this.possibleInvestments) {
      let investment = this.possibleInvestments[investmentName];
      this.portfolio[investment.name] =
        (portfolioMarketValue *
          (investment.marketValue / totalMarketSharePrices)) /
        investment.marketValue;
    }
  }

  getPortfolioMarketValue() {
    let totalMarketValue = 0;
    for (let investmentName in this.portfolio) {
      totalMarketValue +=
        this.portfolio[investmentName] /
        this.possibleInvestments[investmentName].marketCap;
    }
    return totalMarketValue;
  }
}

class ActiveInvestor extends Investor {
  constructor(startingInvestment, possibleInvestments, investorQuality) {
    super(startingInvestment, possibleInvestments);
    this.quality = investorQuality; // TODO
    this.revenue = 0;
    this.name = "TODO";
  }

  allocateCapital() {
    if (Object.keys(this.portfolio).length === 0) {
      super.allocateCapital();
    } else {
      // Value all investments
      let savedEstimatedValues = {};
      let totalEstimatedValue = 0;
      for (let investmentName in this.possibleInvestments) {
        let investment = this.possibleInvestments[investmentName];
        let estimatedActualValue = Utils.randomFromNormal(
          investment.actualValue,
          (1 - this.quality) * investment.actualValue // TODO: refine, 1 quality is perfect. 0 quality bad
        );
        estimatedActualValue = Math.max(0, estimatedActualValue);
        savedEstimatedValues[investmentName] = estimatedActualValue;
        totalEstimatedValue += estimatedActualValue;
      }

      // Allocate funds based on estimated investment value
      let periodStartPortfolioValue = this.getPortfolioMarketValue();
      for (let investmentName in this.possibleInvestments) {
        let investment = this.possibleInvestments[investmentName];
        this.portfolio[investmentName] =
          (periodStartPortfolioValue *
            (savedEstimatedValues[investmentName] / totalEstimatedValue)) /
          investment.marketValue;
      }
    }
  }
}

class Investment {
  constructor(name, startingValue, amountInvested) {
    this.actualCap = startingValue;
    this.marketCap = startingValue;
    this.amountInvested = this.amountInvested;
    this.name = name;
  }
}

const TOTAL_MARKET_CAP = 34000000000;

export default class ActivePassiveSim extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Inputs
      percentActiveFunds: 50,
      investorQuality: 1,
      daysToSimulate: 365 * 30,
      avgAnnualMarketGrowth: 0.07,
      avgAnnualMarketStdev: 0.2,
      activeInvestorPercentFee: 1, // TODO (also dead weight loss)
      rebalance: false,
      numInvestors: 100,
      numInvestments: 10,
      // Outputs
      rawActiveCAGR: undefined,
      rawPassiveCAGR: undefined,
      inefficiencyLoss: undefined,
      percentOutperformers: undefined,
    };
  }

  noOp(e) {
    e.preventDefault();
  }

  getFieldUpdateFunction(fieldName) {
    return function (e) {
      let stateUpdate = {};
      if (e.target.type === "checkbox") {
        stateUpdate[fieldName] = e.target.checked;
      } else {
        stateUpdate[fieldName] = e.target.value;
      }
      this.setState(stateUpdate);
    }.bind(this);
  }

  runSimulation(e) {
    let market = new Market(
      TOTAL_MARKET_CAP,
      this.state.numInvestments,
      this.state.numInvestors,
      this.state.percentActiveFunds,
      this.state.investorQuality
    );
    for (let i = 0; i < 100; i++) {
      market.allocateCapital();
      market.growInvestments(
        this.state.avgAnnualMarketGrowth,
        this.state.avgAnnualMarketStdev
      );
      const dailyAvgGrowth = this.state.avgAnnualMarketGrowth / 252; // TODO: total days? or trading days?
      market.updateMarketPrices(dailyAvgGrowth);
      console.log("PASSIVE CAGR", market.getPassiveCAGR(i));
      console.log("ACTIVE  CAGR", Utils.median(market.getActiveCAGRS(i)));
    }
  }

  render() {
    return (
      <form
        className="rant"
        onSubmit={this.noOp.bind(this)}
        style={{ paddingTop: "0px" }}
      >
        <div>
          <h4>Primary Parameters</h4>
          <label
            htmlFor="percent-active-funds"
            style={{ marginTop: "3px", marginRight: "3px" }}
          >
            % active funds ={" "}
          </label>
          <input
            type="string"
            id="percent-active-funds"
            name="percentActiveFunds"
            value={this.state.percentActiveFunds}
            onChange={this.getFieldUpdateFunction("percentActiveFunds")}
          ></input>
          <label
            htmlFor="percent-passive-funds"
            style={{ marginTop: "3px", marginRight: "3px" }}
          >
            % active funds ={" "}
          </label>
          <input
            type="string"
            id="percent-passive-funds"
            name="percentPassiveFunds"
            value={100 - this.state.percentActiveFunds}
            onChange={this.getFieldUpdateFunction("percentPassiveFunds")}
            disabled={true}
          ></input>
          <br></br>
          <label
            htmlFor="investor-quality"
            style={{ marginTop: "3px", marginRight: "3px" }}
          >
            Investor Quality (σ) ={" "}
          </label>
          <input
            type="string"
            id="investor-quality"
            name="investorQuality"
            value={this.state.investorQuality}
            onChange={this.getFieldUpdateFunction("investorQuality")}
            disabled={true}
          ></input>
          <br></br>
          <label
            htmlFor="days-to-simulate"
            style={{ marginTop: "3px", marginRight: "3px" }}
          >
            Days to simulate ={" "}
          </label>
          <input
            type="string"
            id="days-to-simulate"
            name="daysToSimulate"
            value={this.state.daysToSimulate}
            onChange={this.getFieldUpdateFunction("daysToSimulate")}
            disabled={true}
          ></input>
          <br></br>
          <label
            htmlFor="mean-growth"
            style={{ marginTop: "3px", marginRight: "3px" }}
          >
            Annual Mean Growth ={" "}
          </label>
          <input
            type="string"
            id="mean-growth"
            name="avgAnnualMarketGrowth"
            value={this.state.avgAnnualMarketGrowth}
            onChange={this.getFieldUpdateFunction("avgAnnualMarketGrowth")}
            disabled={true}
          ></input>
          <br></br>
          <label
            htmlFor="stdev-growth"
            style={{ marginTop: "3px", marginRight: "3px" }}
          >
            Annual Stddev growth ={" "}
          </label>
          <input
            type="string"
            id="stdev-growth"
            name="avgAnnualMarketStdev"
            value={this.state.avgAnnualMarketStdev}
            onChange={this.getFieldUpdateFunction("avgAnnualMarketStdev")}
            disabled={true}
          ></input>
          <br></br>
          <label
            htmlFor="managed-fee"
            style={{ marginTop: "3px", marginRight: "3px" }}
          >
            Management fee ={" "}
          </label>
          <input
            type="string"
            id="managed-fee"
            name="activeInvestorPercentFee"
            value={this.state.activeInvestorPercentFee}
            onChange={this.getFieldUpdateFunction("activeInvestorPercentFee")}
            disabled={true}
          ></input>
          <br></br>
          <label
            htmlFor="num-investors"
            style={{ marginTop: "3px", marginRight: "3px" }}
          >
            Number of investors ={" "}
          </label>
          <input
            type="string"
            id="num-investors"
            name="numInvestors"
            value={this.state.numInvestors}
            onChange={this.getFieldUpdateFunction("numInvestors")}
            disabled={true}
          ></input>
          <br></br>
          <label
            htmlFor="num-investments"
            style={{ marginTop: "3px", marginRight: "3px" }}
          >
            Management fee ={" "}
          </label>
          <input
            type="string"
            id="num-investments"
            name="numInvestments"
            value={this.state.numInvestments}
            onChange={this.getFieldUpdateFunction("numInvestments")}
            disabled={true}
          ></input>
          <button onClick={this.runSimulation.bind(this)} class="button">
            Run Simulation
          </button>
        </div>
      </form>
    );
  }
}
