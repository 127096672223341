import React from "react";

interface RefreshableIframeProps {
  width: number;
  height: number;
  refreshable: boolean;
  src: string;
  style?: any;
}

export default class RefreshableIframe extends React.Component<
  RefreshableIframeProps
> {
  iframe: React.RefObject<HTMLIFrameElement>;

  constructor(props: RefreshableIframeProps) {
    super(props);
    this.iframe = React.createRef();
  }

  render() {
    return (
      <div
        id="loading"
        style={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <iframe
          ref={this.iframe}
          src={this.props.src}
          width={this.props.width}
          height={this.props.height}
          style={{ ...this.props.style }}
        ></iframe>
        <button
          style={{ marginTop: "5px" }}
          className="button-lite"
          onClick={() => {
            if (this.iframe.current) {
              // Refresh
              this.iframe.current.src = this.props.src;
            }
          }}
        >
          Reset
        </button>
      </div>
    );
  }
}
