import React from "react";

import { Component } from "react";

import Utils from "../helpers/utils";

class KellyCriterionCalc extends Component {
  constructor(props) {
    super(props);
    this.commentSubmitForm = React.createRef();

    this.STARTING_STATE = {
      mean: 0.092,
      stddev: 0.2,
      r: 0.015,
    };
    this.state = this.STARTING_STATE;
  }

  getFieldUpdateFunction(fieldName) {
    return function (e) {
      let stateUpdate = {};
      if (e.target.type === "checkbox") {
        stateUpdate[fieldName] = e.target.checked;
      } else {
        stateUpdate[fieldName] = e.target.value;
      }
      this.setState(stateUpdate);
    }.bind(this);
  }

  evaluateFormula(mean, stddev, r) {
    return (mean - r) / (stddev * stddev);
  }

  componentDidUpdate() {}

  render() {
    let kellyBet = this.evaluateFormula(
      this.state.mean,
      this.state.stddev,
      this.state.r
    );

    return (
      <form className="rant" style={{ paddingTop: "0px" }}>
        <h4>Continuous Kelly Calculator</h4>
        <div className="form-flex-outer">
          <div className="form-flex-inner">
            <label htmlFor="mean" style={{ marginTop: "3px" }}>
              Mean (μ)
            </label>
            <input
              type="number"
              step=".01"
              id="mean"
              name="mean"
              value={this.state.mean}
              onChange={this.getFieldUpdateFunction("mean")}
              style={{ width: "100px" }}
            />
          </div>
          <div className="form-flex-inner">
            <label htmlFor="stddev" style={{ marginTop: "3px" }}>
              Std Deviation (σ)
            </label>
            <input
              type="number"
              step=".01"
              id="stddev"
              name="stddev"
              value={this.state.stddev}
              onChange={this.getFieldUpdateFunction("stddev")}
              style={{ width: "100px" }}
            />
          </div>
          <div className="form-flex-inner">
            <label htmlFor="r" style={{ marginTop: "3px" }}>
              Alternate Rate (r)
            </label>
            <input
              type="number"
              step=".01"
              id="r"
              name="r"
              value={this.state.r}
              onChange={this.getFieldUpdateFunction("r")}
              style={{ width: "100px" }}
            />
          </div>
        </div>
        <div>
          Optimal Kelly Investment (f*): <b>{Utils.round(kellyBet, 3)}</b>
        </div>
        <div style={{ marginTop: "3px" }}>
          i.e. if your have $100, the kelly investment would be{" "}
          <b>{Utils.formatMoney(100 * kellyBet)}</b>
        </div>
      </form>
    );
  }
}
export default KellyCriterionCalc;
